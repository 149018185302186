<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.maintainer.title") }}</h3>
    <div class="mb-5">
      <h4 class="font-weight-normal">
        {{ $t("page.maintainer.associatedMaintainers") }}
      </h4>
      <maintainer-table
        :maintainers="formattedMaintainers"
        :fields="fields"
        :actions="actions"
        :sortBy.sync="sortBy"
        :sortDesc.sync="sortDesc"
      />
    </div>
    <div>
      <h4 class="font-weight-normal">
        {{ $t("page.maintainer.addMainainer") }}
      </h4>
      <b-card>
        <h5 class="font-weight-normal mb-3">
          {{ $t("page.maintainer.searchMaintainerMsg") }}
        </h5>
        <maintainer-filters storeName="maintainer" />
        <b-alert
          show
          variant="warning"
          class="mt-3 mb-0"
          v-html="
            $t('page.maintainer.totalMaintainerResults', {
              total: totalMaintainers
            })
          "
        ></b-alert>
        <g-map
          :centerCoordinates="mapCenterCoordinates"
          :markers="mapMarkers"
        />
      </b-card>
    </div>

    <maintainer-details
      :modalState.sync="editModalState"
      :maintainer="selectedMaintainer"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { EventBus } from "./../eventBus.js";

import GMap from "./../components/Google/GoogleMaps";
import MaintainerTable from "./../components/Maintainer/MaintainerTable";
import MaintainerFilters from "./../components/Maintainer/MaintainerFilters";
import MaintainerDetails from "./../components/Maintainer/MaintainerDetails";

import CondominiumMapInfo from "./../components/Condominium/CondominiumMapInfo";
const InfoWindow = Vue.extend(CondominiumMapInfo);
export default {
  components: {
    GMap,
    MaintainerTable,
    MaintainerFilters,
    MaintainerDetails
  },
  data() {
    return {
      markerIcons: {
        condominium: require("@/assets/marker-proCondominium.png"),
        freeCondominium: require("@/assets/marker-freeCondominium.png"),
        maintainer: require("@/assets/marker-maintainer.png"),
        assignedMaintainer: require("@/assets/marker-assignedMaintainer.png")
      },
      fields: [
        { key: "name", label: "legalName", sort: true },
        { key: "type", label: "expertiseAreas", sort: false },
        { key: "actions", label: "", sort: false }
      ],
      actions: [
        {
          label: "button.details",
          callbackFunc: this.openMaintainerDetails
        },
        {
          label: "button.delete",
          variant: "orange",
          callbackFunc: this.openConfirmationModal
        }
      ],
      sortBy: "name",
      sortDesc: false,
      editModalState: false,
      selectedMaintainer: null
    };
  },
  mounted() {
    EventBus.$on("eventBusReloadMaintainers", this.reloadData);
  },
  computed: {
    user() {
      return this.$store.getters["auth/getAdminInfo"];
    },
    condominiums() {
      return this.$store.getters["condominium/getActiveCondominiums"];
    },
    maintainers() {
      return this.$store.getters["maintainer/getMaintainers"].filter(
        m => !m.userId || (m.userId && m.userId.status === "active")
      );
    },
    selectedAdmin() {
      return this.$store.getters["filter/getAdministratorSelected"];
    },
    adminMaintainers() {
      const adminMaintainers = this.$store.getters[
        "maintainer/getAdminMaintainers"
      ];

      let maintainers = adminMaintainers.filter(
        m => !m.userId || (m.userId && m.userId.status === "active")
      );
      if (this.user.type === "domi") {
        if (this.selectedAdmin) {
          maintainers = this.maintainers.filter(m =>
            m.administrators.includes(this.selectedAdmin._id)
          );
        } else {
          maintainers = this.maintainers.filter(m => m.administrators.length);
        }
      }

      return maintainers.length ? maintainers : adminMaintainers;
    },
    formattedMaintainers() {
      return this.adminMaintainers.map(item => {
        return {
          _id: item._id,
          name: item.name,
          type: item.type
        };
      });
    },
    totalMaintainers() {
      return this.maintainers.length;
    },
    mapMarkers() {
      const maintainers = this.maintainers.map(maintainer => {
        let icon = "";
        if (this.selectedAdmin) {
          icon = maintainer.administrators.includes(this.selectedAdmin._id)
            ? this.markerIcons.assignedMaintainer
            : this.markerIcons.maintainer;
        } else if (this.user.type === "administrator") {
          console.log("in");
          icon = maintainer.administrators.includes(this.user._id)
            ? this.markerIcons.assignedMaintainer
            : this.markerIcons.maintainer;
        } else {
          icon = maintainer.administrators.length
            ? this.markerIcons.assignedMaintainer
            : this.markerIcons.maintainer;
        }
        return {
          _id: maintainer._id,
          position: { lat: maintainer.latitude, lng: maintainer.longitude },
          content: null,
          icon,
          callbackFunc: this.openMaintainerDetails
        };
      });

      const condominiums = this.condominiums.map(condominium => {
        return {
          _id: condominium._id,
          position: { lat: condominium.latitude, lng: condominium.longitude },
          content: this.condominiumDOMElement(condominium),
          icon: this.markerIcons.condominium
        };
      });

      return condominiums.concat(maintainers);
    },
    mapCenterCoordinates() {
      return this.mapMarkers.length > 0 ? this.mapMarkers[0].position : null;
    }
  },
  methods: {
    async openMaintainerDetails(maintainerId) {
      this.selectedMaintainer = this.maintainers.find(
        item => item._id === maintainerId
      );
      this.editModalState = true;
    },
    condominiumDOMElement(condominium) {
      return new InfoWindow({
        propsData: {
          condominium: condominium,
          router: this.$router
        }
      }).$mount().$el;
    },
    async reloadData() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("maintainer/retrieveMaintainers");
        await this.$store.dispatch("condominium/retrieveCondominiums");
      } catch (error) {
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    openConfirmationModal(maintainerId, maintainerName) {
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "confirm.removeMainterTitle",
        messageText: "confirm.removeMaintainerMessage",
        messageParams: { maintainerName },
        confirmBtnCallback: this.deleteAdminMaintainer,
        confirmBtnParams: { maintainerId },
        type: "DELETE"
      });
    },
    async deleteAdminMaintainer({ maintainerId }) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch(
          "maintainer/deleteAdminMaintainer",
          maintainerId
        );
        await this.$store.dispatch("maintainer/retrieveAdminMaintainers");
        await this.$store.dispatch("maintainer/retrieveMaintainers");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadMaintainers");
  }
};
</script>

<style lang="scss" scoped>
.alert {
  border-left: 10px solid var(--yellow);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
